import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {AuthorizeIframe} from '~/components/AuthorizeIframe';
import {Button} from '~/components/Button';
import {ShopIcon} from '~/components/ShopIcon';
import {SIGN_TOAST_KEY} from '~/constants/toast';
import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {RootProvider} from '~/foundation/RootProvider/RootProvider';
import {useAuthorizeUrl} from '~/hooks/useAuthorizeUrl';
import {useDebouncedCallback} from '~/hooks/useDebounce';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useElementEventListener} from '~/hooks/useElementEventListener';
import {useEmailListener} from '~/hooks/useEmailListener';
import type {AnalyticsContext} from '~/types/analytics';
import type {SdkErrorCode} from '~/types/authorize';
import type {
  AuthorizeErrorEvent,
  CompletedEvent,
  StartEvent,
} from '~/types/event';
import type {ShopActionType} from '~/types/flow';
import type {IframeElement} from '~/types/iframe';
import {isMobileWebview} from '~/utils/browser';
import {exchangeLoginCookie} from '~/utils/cookieExchange';
import {isoDocument} from '~/utils/document';
import register from '~/utils/register';
import {displayToast, setToastInStorage} from '~/utils/toast';
import {openWindoid} from '~/utils/windoid';
import {isoWindow} from '~/utils/window';

import {getAnalyticsProps} from './monorail';
import type {
  ShopLoginButtonProps,
  ShopLoginButtonWebComponentProps,
} from './types';

// Flows to show a same-page Toast on
const SAME_PAGE_TOAST_FLOWS: AnalyticsContext[] = ['loginWithShop'];

const ShopLoginButtonInternal = ({
  action,
  activatorId,
  analyticsContext = 'loginWithShop',
  anchorTo,
  autoOpen,
  email,
  emailInputSelector,
  firstname,
  hideButton,
  lastname,
  persistAfterSignIn = true,
  proxy = false,
  uxMode = 'iframe',
  version,
  ...props
}: ShopLoginButtonProps) => {
  const {notify} = useBugsnag();
  const {trackPageImpression, trackUserAction} = useMonorail();
  const {translate} = useI18n();
  const [contentVisible, setContentVisible] = useState(true);
  const dispatchEvent = useDispatchEvent();

  const activatorElementRef = useRef<HTMLElement | null>(
    activatorId ? isoDocument.getElementById(activatorId) : null,
  );
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const iframeRef = useRef<IframeElement | null>(null);
  const windoidRef = useRef<WindowProxy | null>(null);
  const [error, setError] = useState<SdkErrorCode>();

  const mobileWebView = useMemo(() => isMobileWebview(), []);
  const adjustedUxMode = useMemo(() => {
    // redirect is either requested (= via uxMode),
    // or defaulted to (if uxMode is windoid but we are in a web view)
    const isRedirect =
      uxMode === 'redirect' || (uxMode === 'windoid' && mobileWebView);

    return isRedirect ? 'redirect' : uxMode;
  }, [mobileWebView, uxMode]);

  const {getSubmittedEmail, updateEmailToPost, updateNamesToPost} =
    useEmailListener({
      autoOpen,
      email,
      emailInputSelector,
      iframeRef,
    });

  useEffect(() => {
    updateNamesToPost(firstname, lastname);
  }, [firstname, lastname, updateNamesToPost]);

  const elementEventListeners = useMemo(() => {
    const start = async ({detail}: StartEvent) => {
      setError(undefined);
      const email = detail.email;
      await updateEmailToPost(email);
    };
    return {
      requestShow: start,
      start,
    };
  }, [updateEmailToPost]);

  useElementEventListener(elementEventListeners);

  useEffect(() => {
    if (action) {
      if (action === 'follow') {
        // eslint-disable-next-line no-console
        console.warn?.(
          '[Sign in with Shop] The action prop is deprecated, please use <shop-follow-button> instead.',
        );
      } else {
        // eslint-disable-next-line no-warning-comments
        // TODO: We removed the bugsnag event since it was extremely noisy.
        // We can iterate on how to deal with invalid configs in https://github.com/Shopify/shop-identity/issues/3519.
        // eslint-disable-next-line no-console
        console.warn?.(
          `[Sign in with Shop] Received deprecated attribute: action, "${action}"`,
        );
      }
    }
  }, [action, notify]);

  useEffect(() => {
    if (!hideButton) {
      trackPageImpression({
        page: 'SIGN_IN_WITH_SHOP_BUTTON',
      });
    }
  }, [hideButton, trackPageImpression]);

  const handleComplete = useCallback(
    async ({
      email,
      loggedIn,
      shouldFinalizeLogin,
      givenName,
      userInteracted,
      redirectUri,
    }: CompletedEvent) => {
      if (!persistAfterSignIn) {
        setContentVisible(false);
      }

      let actionText;
      if (!userInteracted) {
        actionText = translate('shopLoginButton.toast.action') as string;
      }

      let text = translate('shopLoginButton.toast.default') as string;
      if (givenName) {
        text = translate('shopLoginButton.toast.user', {
          user: givenName,
        }) as string;
      }

      const toast = {
        actionText,
        subText: email,
        text,
      };

      if (SAME_PAGE_TOAST_FLOWS.includes(analyticsContext)) {
        // display the toast immediately in some cases
        displayToast(toast);
      } else if (adjustedUxMode === 'windoid') {
        // only if windoid, set toast copy in storage so that it can be
        // retrieved and rendered at the next page load
        setToastInStorage(SIGN_TOAST_KEY, toast);
      }

      if (loggedIn && shouldFinalizeLogin) {
        await exchangeLoginCookie(props.storefrontOrigin, notify);
        /**
         * TODO: In the future we will publish an event to shop hub to create a user session.
         *
         * Issue: https://github.com/Shopify/shop-identity/issues/2859
         * Pull request: https://github.com/Shopify/shop-js/pull/2363
         */
      }

      if (redirectUri) {
        isoWindow.location.href = redirectUri;
      }
    },
    [
      adjustedUxMode,
      analyticsContext,
      notify,
      persistAfterSignIn,
      props.storefrontOrigin,
      translate,
    ],
  );

  // eslint-disable-next-line no-warning-comments
  // TODO: Remove this once the Installments components are ported to Preact
  // https://github.com/Shopify/shop-server/issues/82525
  const isPrequalFlow = analyticsContext === 'loginWithShopPrequal';
  const prequalProps = isPrequalFlow
    ? {
        disableSignUp: false,
        flow: 'prequal' as ShopActionType,
        flowVersion: 'unspecified',
        hideCopy: false,
        isCompactLayout: false,
        proxy: true,
      }
    : {};

  const shouldProxy = useMemo(() => {
    if (version !== undefined) {
      // eslint-disable-next-line no-warning-comments
      // TODO: We removed the DeprecatedAttributeError bugsnag event, since it was extremely noisy.
      // We can iterate on how to deal with invalid configs in https://github.com/Shopify/shop-identity/issues/3519.
      // eslint-disable-next-line no-console
      console.warn?.(
        `[Sign in with Shop] Received deprecated attribute: version, "${version}"`,
      );
    }

    return proxy || version === '2';
  }, [proxy, version]);

  const src = useAuthorizeUrl({
    analyticsContext,
    error,
    ...props,
    proxy: shouldProxy,
    uxMode: adjustedUxMode,
    ...prequalProps,
  });

  function onError({code, email, message}: AuthorizeErrorEvent): void {
    dispatchEvent('error', {
      code,
      email,
      message,
    });

    if (code === 'retriable_server_error') {
      if (error === code) {
        iframeRef.current?.reload();
      }
      setError(code);
    }
  }

  const handleWindoidMessage = useCallback(
    (payload: MessageEvent) => {
      if (payload.data.type === 'completed') {
        handleComplete(payload.data as any);
        dispatchEvent('completed', payload.data, true);
        windoidRef.current?.close();
      }
    },
    [dispatchEvent, handleComplete],
  );

  const openWindoidOrRedirect = useCallback(
    (src: string) => {
      switch (adjustedUxMode) {
        case 'windoid':
          windoidRef.current = openWindoid(src, handleWindoidMessage);
          break;
        case 'redirect':
          isoWindow.location.href = src;
          break;
      }
    },
    [adjustedUxMode, handleWindoidMessage],
  );

  const handleButtonClick = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_BUTTON_CLICK',
    });

    openWindoidOrRedirect(src);
  }, [openWindoidOrRedirect, src, trackUserAction]);

  const debouncedButtonClick = useDebouncedCallback(
    handleButtonClick,
    150,
    true,
  );

  const handlePromptChange = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_PROMPT_CHANGE_CLICK',
    });
    iframeRef?.current?.close({
      dismissMethod: 'prompt_change',
      reason: 'user_prompt_change_clicked',
    });
  }, [trackUserAction]);

  const handlePromptContinue = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_PROMPT_CONTINUE_CLICK',
    });

    iframeRef?.current?.close({
      dismissMethod: 'windoid_continue',
      reason: 'user_prompt_continue_clicked',
    });
    const email = getSubmittedEmail();
    const newSrcBase = email
      ? `${src}&login_start=${encodeURIComponent(email)}`
      : src;
    const newSrc = `${newSrcBase}&origin=preauth_prompt`;

    openWindoidOrRedirect(newSrc);
  }, [getSubmittedEmail, openWindoidOrRedirect, src, trackUserAction]);

  const button =
    hideButton || activatorElementRef.current || !contentVisible ? null : (
      <Button onClick={debouncedButtonClick} ref={buttonRef} className="m-auto">
        <span className="cursor-pointer whitespace-nowrap px-11 py-4 font-sans text-button-large text-white">
          {translate('shopLoginButton.continue', {
            shop: <ShopIcon className="relative inline-block h-4 w-auto" />,
          })}
        </span>
      </Button>
    );

  const isIframe = uxMode === 'iframe';
  const activator = activatorElementRef.current
    ? activatorElementRef
    : buttonRef;
  const iframeActivator = isIframe ? activator : undefined;
  const iframeSrc = isIframe ? src : `${src}&ux_role=prompt`;
  const modalHeaderTitle = isIframe
    ? ''
    : (translate('shopLoginButton.title', {defaultValue: ''}) as string);

  return (
    <>
      {button}
      <AuthorizeIframe
        activator={iframeActivator}
        anchorTo={anchorTo}
        autoOpen={autoOpen && !windoidRef.current}
        modalHeaderTitle={modalHeaderTitle}
        onComplete={handleComplete}
        onError={onError}
        onPromptChange={handlePromptChange}
        onPromptContinue={handlePromptContinue}
        proxy={shouldProxy}
        ref={iframeRef}
        src={iframeSrc}
        variant="default"
      />
    </>
  );
};

const getFeatureDictionary = async (locale: string) =>
  // Temporary adding an await here so that the i18n-dynamic-import-replacer
  // replaces the import statement with the correct file.
  // https://github.com/Shopify/shop-identity/issues/2814
  // eslint-disable-next-line no-return-await
  await import(`./translations/${locale}.json`);

register<ShopLoginButtonProps>(
  ({element, ...props}) => (
    <RootProvider
      element={element}
      featureName="ShopLoginButton"
      getFeatureDictionary={getFeatureDictionary}
      monorailProps={getAnalyticsProps(props)}
    >
      <ShopLoginButtonInternal {...props} />
    </RootProvider>
  ),
  {
    methods: ['requestShow', 'start', 'showToast'],
    name: 'shop-login-button',
    props: {
      action: 'string',
      activatorId: 'string',
      analyticsContext: 'string',
      anchorTo: 'string',
      apiKey: 'string',
      autoOpen: 'boolean',
      avoidPayAltDomain: 'boolean',
      checkoutVersion: 'string',
      clientId: 'string',
      codeChallenge: 'string',
      codeChallengeMethod: 'string',
      consentChallenge: 'string',
      disableSignUp: 'boolean',
      email: 'string',
      emailInputSelector: 'string',
      firstname: 'string',
      flow: 'string',
      flowVersion: 'string',
      hideButton: 'boolean',
      keepModalOpen: 'boolean',
      lastname: 'string',
      persistAfterSignIn: 'boolean',
      popUpFeatures: 'string',
      popUpName: 'string',
      proxy: 'boolean',
      redirectType: 'string',
      redirectUri: 'string',
      requireVerification: 'boolean',
      responseMode: 'string',
      responseType: 'string',
      returnUri: 'string',
      scope: 'string',
      source: 'string',
      state: 'string',
      uxMode: 'string',
      version: 'string',
    },
    shadow: 'open',
  },
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace preact.JSX {
    interface IntrinsicElements {
      ['shop-login-button']: ShopLoginButtonWebComponentProps;
    }
  }
}

export function ShopLoginButton(props: ShopLoginButtonWebComponentProps) {
  return <shop-login-button {...props} />;
}
